import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Margalin Tutor </span>
            from <span className="purple"> Edmonton, Canada.</span>
            <br />
            Dedicated and efficient full stack developer with 5 years experience
            in application layers, presentation layers, and databases. Part of
            the team that developed and implemented new data-driven
            features at Memrise, increasing customer engagement
            by 60%. Seeking opportunity to upgrade backend and improve
            customer satisfaction rating at Duolingo.
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
